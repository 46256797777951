import { Injectable } from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';
import {TokenInterceptor} from './TokenInterceptor';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  public eMailAddr;
  public userIsLoggedIn;
  
  constructor(private oauthService: OAuthService) {}

  public configure = new Promise((resolve, reject) => {
    this.oauthService.configure(authConfig);
    console.log('hello world')

    this.oauthService.tryLogin().then(() => {
      if (!this.oauthService.hasValidIdToken()) {
        this.oauthService.initImplicitFlow();
      } else {
        this.oauthService.loadDiscoveryDocument().then(() => {
          console.log('heeey')
          this.oauthService.setupAutomaticSilentRefresh();
          console.log(this.oauthService.tokenEndpoint);
          this.oauthService.loadUserProfile().then( res => {
            this.eMailAddr = res.email.toLowerCase();
            resolve(); 
          });
        });
        //TokenInterceptor.removeAccessToken();
        //TokenInterceptor.setAccessToken(this.oauthService.getAccessToken());
        console.log('Hello World 2')
        console.log(this.oauthService.getIdentityClaims());
        console.log(this.oauthService.getAccessToken());
        sessionStorage.token = this.oauthService.getIdToken();
        this.userIsLoggedIn = true;
      }
    });
  });
  
}

import {Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';
import {TokenInterceptor} from './TokenInterceptor';
import {ApiService} from './api.service';
import{UserDataService} from './user-data.service';
import {FormBuilder} from '@angular/forms';
import { LanguageSwitchService } from './language-switch.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'mailinglistfrontend';
  filtersLoaded: Promise<boolean>;
  articles;
  checkoutForm;
  eMailAddr = '';
  subscriptionArticles = [];
  searchParameter = '';
  alreadySubscribedArticles: any = [];
  message1 = null;
  message2 = null;
  loadingSpinner = false;
  loadingList = true;
  syncmessage = '';

  constructor(private apiService: ApiService, private oauthService: OAuthService, private formBuilder: FormBuilder, public userDataService: UserDataService, public languageSwitch: LanguageSwitchService) {
    this.configure();
    this.checkoutForm = this.formBuilder.group({
      article: '',
      email : '',
    });
    this.syncmessage = this.languageSwitch.get('text_search_placeholder');
  }

  public searchFn(term, item){
    return true;
  }

  public isSyncTime(){
    let tZone = 'Europe/Berlin';

    let hour = parseInt(new Date().toLocaleString("de-DE", { timeZone: tZone }).replace(/^.*?(\d\d):(\d\d):\d\d$/, '$1'));
    let minute = parseInt(new Date().toLocaleString("de-DE", { timeZone: tZone }).replace(/^.*?(\d\d):(\d\d):\d\d$/, '$2'));

    let syncTime = false;
    if((hour === 7 && minute > 58) ||
       (hour === 8 && minute < 4) ||
       (hour === 13 && minute > 58) ||
       (hour === 14 && minute < 4)){
      syncTime = true;
    }

    return syncTime;
  }

  public toggleAll(e){
    if(e.value === 'all' && e.selected)
      e.selectionList.selectAll();
    else if(e.value === 'all' && !e.selected)
      e.selectionList.deselectAll();
  }

  private configure() {
    this.loadingSpinner = true;
    this.userDataService.configure.then(res => {
      this.eMailAddr = this.userDataService.eMailAddr.toLowerCase();
      this.loadingSpinner = false;
      this.loadAlreadySubscribedArticles();
    });
  }

  public searchResults(filter: string, jsonBody: string){
    if(this.isSyncTime()) return;
    this.loadingList = true;
    this.userDataService.configure.then(res => {
      if (this.userDataService.userIsLoggedIn) {
        this.apiService.getFilteredArticles(filter, jsonBody).subscribe(res => {
          this.articles = res;
          for(let i in this.articles){
            this.articles[i].fullSearch = this.articles[i].artikel_id + ' | ' + this.cleanDescription(this.articles[i].artikel_bezeichnung);
          }
          this.filtersLoaded = Promise.resolve(true);
          this.loadingList = false;
        });
      }     
    });
  }

  public close(select){
    select.searchTerm = this.searchParameter;
  }

  private searchTimeout = setTimeout(() => {}, 0);
  public search(search){
    this.searchParameter = search.term;

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout( this.searchResults.bind(this, this.searchParameter, this.jsonListToString(this.alreadySubscribedArticles.concat(this.subscriptionArticles), 'artikel_id')), 250);
  }

  public add(){
    this.message1 = null;
    this.message2 = null;
    this.searchResults.call(this, this.searchParameter, this.jsonListToString(this.alreadySubscribedArticles.concat(this.subscriptionArticles), 'artikel_id'));
  }

  public startSubscription(){
    this.message1 = null;
    this.message2 = null;

    if(this.isSyncTime()){
      this.message1 = {type:'error', message: this.syncmessage};
      this.message2 = {type:'error', message: this.syncmessage};
      return;
    }

    if(confirm(this.languageSwitch.get('message_confirm_abo_all'))){
      this.loadingSpinner = true;
      let subscribeList = "[";
      let i = 0;
      for(let key in this.subscriptionArticles){
        i++;
        subscribeList += '{"artikel_id":"' + this.subscriptionArticles[key].artikel_id + '","email":"' + this.eMailAddr + '"}';
        if(i < Object.keys(this.subscriptionArticles).length)
          subscribeList += ',';
      }
      subscribeList += "]";
      this.apiService.subscribeToArticle(subscribeList).subscribe(
        res => {
          this.subscriptionArticles = [];
          this.loadAlreadySubscribedArticles();
          this.message1 = {type:'notification_important', message: this.languageSwitch.get('info_success_abo')};
          this.loadingSpinner = false;
        },
        res => {
          this.message1 = {type:'error', message: 'Error: ' + res.error.message};
          this.loadingSpinner = false;
        }
      );
    }
  }

  public startUnSubscription(list){
    this.message1 = null;
    this.message2 = null;

    if(this.isSyncTime()){
      this.message1 = {type:'error', message: this.syncmessage};
      this.message2 = {type:'error', message: this.syncmessage};
      return;
    }

    if(confirm(this.languageSwitch.get('message_confirm_remove_all'))){
      this.loadingSpinner = true;
      let unsubscribeList = "[";
      let i = 0;
      for(let key in list){
        i++;
        unsubscribeList += '"' + list[key] + '"';
        if(i < Object.keys(list).length)
          unsubscribeList += ',';
      }
      unsubscribeList += "]";
      this.apiService.unsubscribeFromArticle(unsubscribeList).subscribe(
        res => {
          this.loadAlreadySubscribedArticles();
          this.message2 = {type:'notification_important', message: this.languageSwitch.get('info_success_remove')};
          this.loadingSpinner = false;
        },
        res => {
          this.message2 = {type:'error', message: 'Error: ' + res.error.message};
          this.loadingSpinner = false;
        }
      );
    }
  }

  public removeSelected(list){
    this.message1 = null;
    this.message2 = null;

    if(this.isSyncTime()){
      this.message1 = {type:'error', message: this.syncmessage};
      this.message2 = {type:'error', message: this.syncmessage};
      return;
    }

    let tmp = [];
    for(let key in this.subscriptionArticles){
      let found = false;
      for(let ID in list){
        if(this.subscriptionArticles[key].artikel_id === list[ID]){found = true; break;}
      }
      if(found) continue;
      tmp.push(this.subscriptionArticles[key]);
    }
    this.subscriptionArticles = tmp;
  }

  private loadAlreadySubscribedArticles(){
    this.apiService.getAlreadySelectedArticles(this.eMailAddr).subscribe(res => {
      this.alreadySubscribedArticles = res;
      this.searchResults.call(this, this.searchParameter, this.jsonListToString(this.alreadySubscribedArticles.concat(this.subscriptionArticles), 'artikel_id'));
    });
  }

  public cleanDescription(d){
    if(typeof(d) === 'string')
      return d.replace(/\;+$/g, '').replace(/\;+/g, '; ');
    return '';
  }

  private jsonListToString(list, name: string){
    let str = '[';
    let i = 0;
    for(let key in list){
      i++;
      str += '"' + list[key][name] +  '"';
      if(i < Object.keys(list).length)
        str += ',';
    }
    str += ']';
    return str;
  }

}



import { AuthConfig } from 'angular-oauth2-oidc';

export const TENANT_GUID = '3f71a7de-c5b6-4f30-80e2-9291669f3358';
// Old (PersonalakteBackend)
//export const CLIENT_GUID = '989a364f-b983-40ec-bd17-4a1d7addaad9';
// New (DeliveryAbonnement)
export const CLIENT_GUID = '346b8b7c-f18a-4c3a-ba5c-b810286d1aa0';


export const authConfig: AuthConfig = {

    issuer: 'https://login.microsoftonline.com/' + TENANT_GUID + '/v2.0',
    redirectUri: window.location.origin,
    requestAccessToken: true,
    oidc: true,
    clientId: CLIENT_GUID,
    scope: 'openid profile email',
    strictDiscoveryDocumentValidation : false,
    loginUrl: 'https://login.microsoftonline.com/' + TENANT_GUID + '/oauth2/v2.0/authorize',
    responseType: 'id_token token',
};

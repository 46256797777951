import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageSwitchService {

  lang: string;
  lastLang: string;
  pack: any;
  lastSync: string;
  currSync: string;

  constructor(private apiService: ApiService, private userDataService: UserDataService) {
    this.userDataService.configure.then(resUser => {
      this.apiService.getLanguage().subscribe(res => {
        this.lang = res['language'];
        this.lastLang = localStorage.getItem('aboLanguage');
        this.pack = localStorage.getItem('aboLanguagePack');
        this.lastSync = localStorage.getItem('aboLanguageLastSync');

        this.updateCurrTime();
    
        if(!this.lang || !this.lastSync || parseInt(this.lastSync)+86400 < parseInt(this.currSync) || this.lastLang !== this.lang){
          if(this.lang){
            this.setLang(this.lang);
          }else{
            this.setLang('D');
          }
        }else if(!this.pack){
          this.setLang(this.lang);
        }else{
          this.pack = JSON.parse(this.pack);
        }
      });
    });

  }

  updateCurrTime(){
    let d = new Date();
    this.currSync =  d.getFullYear().toString() + (d.getMonth() < 10 ? '0' : '') + (d.getMonth() + 1).toString() + (d.getDate() < 10 ? '0' : '') + d.getDate().toString() + (d.getHours() < 10 ? '0' : '') + d.getHours().toString() + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes().toString() + (d.getSeconds() < 10 ? '0' : '') + d.getSeconds().toString();
  }

  setLang(lang: string){
    localStorage.setItem('aboLanguage', lang);
    this.lang = lang;
    this.userDataService.configure.then(res => {
      this.apiService.setLanguage(lang, this.userDataService.eMailAddr).subscribe(this.setPack.bind(this));
    });    
  }

  setPack(){
    this.apiService.getLanguagePack().subscribe(res => {
        this.pack = res;
        localStorage.setItem('aboLanguagePack', JSON.stringify(res));
        localStorage.setItem('aboLanguageLastSync', this.currSync);
      //  window.location.reload();
    });
  }

  get(text){
    for(let i in this.pack){
      if(i === text)
        return this.pack[i];
    }
    return "";
  }
}

<div class="pro-alpha-subscription">
  <div class="wrapper">

    
    <div class="header">
      <a class="logo" href="https://world.deutsche-windtechnik.com/"><span class="subtitle">World</span></a>
      <div class="currentPage"><span class="first">{{languageSwitch.get('text_current_page')}}:</span><span class="second">{{languageSwitch.get('text_page_title')}}</span></div>
      <app-language-switch></app-language-switch>
      <div class="welcome">{{userDataService.eMailAddr}}</div>
      <div style="clear:both;"></div>
    </div> 


    <div class="body">
      <div class="bgPickerContinue"></div>

      <div class="content">
        <div class="bgPicker">
          <div class="picker">
            <ng-select *ngIf="!isSyncTime()" #select [items]="articles"    
                    [(ngModel)]="subscriptionArticles"   
                    [virtualScroll]="true"
                    [loading]="loadingList"
                    bindLabel="fullSearch"
                    [multiple]="true"
                    [closeOnSelect]="false"
                    [hideSelected]="false"
                    [searchFn]="searchFn"
                    [clearOnBackspace]="false"
                    (search)="search($event)"
                    (close)="close(select)"
                    (add)="add()"
                    [clearable]="false"
                    [notFoundText]="languageSwitch.get('text_not_found')"
                    [placeholder]="languageSwitch.get('text_search_placeholder')">
                    <ng-template ng-multi-label-tmp></ng-template>
            </ng-select>
            <div *ngIf="isSyncTime()">{{syncmessage}}</div>
          </div>
        </div>

        <div class="bodyPicker" *ngIf="!isSyncTime()">
          <div class="bodyContent"> 
            <mat-tab-group class="height100" animationDuration="0">

              <mat-tab [label]="languageSwitch.get('tab_abo_start')">
                <ngx-simplebar class="contentTab" [options]="{autoHide: false}">
                  <div *ngIf="loadingSpinner" class="spinnerWrapper">
                    <mat-spinner class="loadingSpinner"></mat-spinner>
                  </div>
                  <div *ngIf="!loadingSpinner">
                    <div *ngIf="message1 != null" class="infoText">
                      <mat-icon>{{message1.type}}</mat-icon> {{message1.message}}
                    </div>
                    <div *ngIf="subscriptionArticles.length == 0" class="infoText">
                      <mat-icon>warning</mat-icon> {{languageSwitch.get('warn_no_article_selected')}}
                    </div>
                    <div *ngIf="subscriptionArticles.length > 0">
                      {{languageSwitch.get('text_add_following_articles')}}
                      <mat-selection-list #subscribeList (selectionChange)="toggleAll($event.option)" class="matlist">
                        <mat-list-option checkboxPosition="before" value="all" class="deSelectAll"><span>{{languageSwitch.get('button_select_all')}}</span></mat-list-option>
                        <mat-list-option checkboxPosition="before" [value]="item.artikel_id" *ngFor="let item of subscriptionArticles">
                          <div class="firstline">{{cleanDescription(item.artikel_bezeichnung)}}</div>
                          <div class="secondline">Nr.: {{cleanDescription(item.artikel_id)}}</div>
                        </mat-list-option>
                      </mat-selection-list>
          
                      <div class="fixedButtons">
                        <button mat-flat-button (click)="removeSelected(subscribeList._value)"><mat-icon>delete_forever</mat-icon> {{languageSwitch.get('button_remove_selected1')}}</button>
                        <button mat-flat-button (click)="startSubscription()"><mat-icon>mail</mat-icon> {{languageSwitch.get('button_abo_all')}}</button>
                      </div>
                    </div>
                  </div>
                </ngx-simplebar>
              </mat-tab>

              <mat-tab [label]="languageSwitch.get('tab_abo_manage')">
                <ngx-simplebar class="contentTab" [options]="{autoHide: false}">
                  <div *ngIf="loadingSpinner" class="spinnerWrapper">
                    <mat-spinner class="loadingSpinner"></mat-spinner>
                  </div>
                  <div *ngIf="!loadingSpinner">
                    <div *ngIf="message2 != null"  class="infoText">
                      <mat-icon>{{message2.type}}</mat-icon> {{message2.message}}
                    </div>
                    <div *ngIf="alreadySubscribedArticles.length == 0" class="infoText">
                      <mat-icon>warning</mat-icon> {{languageSwitch.get('warn_no_article_abo')}}
                    </div>
                    <div *ngIf="alreadySubscribedArticles.length > 0">
                      {{languageSwitch.get('text_already_subscribed')}}
                      <mat-selection-list #unsubscribeList (selectionChange)="toggleAll($event.option)" class="matlist">
                        <mat-list-option checkboxPosition="before" value="all" class="deSelectAll"><span>{{languageSwitch.get('button_select_all')}}</span></mat-list-option>
                        <mat-list-option checkboxPosition="before" [value]="item.UUID" *ngFor="let item of alreadySubscribedArticles">
                          <div class="firstline">{{cleanDescription(item.artikel_bezeichnung)}}</div>
                          <div class="secondline">Nr.: {{cleanDescription(item.artikel_id)}}</div>
                        </mat-list-option>
                      </mat-selection-list>

                      <div class="fixedButtons">
                        <button mat-raised-button aria-label="Markierte Abonnements kündigen" (click)="startUnSubscription(unsubscribeList._value)"><mat-icon>unsubscribe</mat-icon> {{languageSwitch.get('button_remove_selected2')}}</button>
                      </div>
                    </div>
                  </div>
                </ngx-simplebar>
              </mat-tab>

            </mat-tab-group>
          </div>
        </div>

        
          
  
        
      </div>
    </div>    

  </div>
</div>


import { Component, OnInit } from '@angular/core';
import { LanguageSwitchService } from 'src/app/language-switch.service';


@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.css']
})
export class LanguageSwitchComponent implements OnInit {

  constructor(public languageSwitch: LanguageSwitchService) {
  }

  ngOnInit(): void {
  }

  switchLanguage(language) {
    this.languageSwitch.setLang(language);
  }


}

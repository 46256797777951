import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

 SERVER_URL = 'https://deliveryabo.deutsche-windtechnik.com/mailbenachrichtigungBackend/';
//SERVER_URL = 'http://localhost:8080/mailbenachrichtigungBackend/';

  constructor(private httpClient: HttpClient) {

  }

  public getMe() {
    return this.httpClient.get('https://graph.microsoft.com/v1.0/users/holger.rohling@deutsche-windtechnik.de?$select=department,displayName,givenName,postalCode');
  }

  public getLanguagePack() {
    return this.httpClient.get(this.SERVER_URL + 'languagePack');
  }

  public getLanguage() {
    return this.httpClient.get(this.SERVER_URL + 'language');
  }

  public setLanguage(lang: string, email: string) {
    return this.httpClient.put(this.SERVER_URL + 'language', {'language':lang, 'email':email}, {headers: {"Content-Type": "application/json"}});
  }

  public getAllArticles() {
    return this.httpClient.get(this.SERVER_URL + 'articles');
  }

  public getFilteredArticles(filter: string, jsonBody: string) {
    return this.httpClient.post(this.SERVER_URL + 'getArticlesFiltered?filter=' + filter, jsonBody, {headers: {"Content-Type": "application/json"}});
  }

  public getAlreadySelectedArticles(email: string) {
    return this.httpClient.get(this.SERVER_URL + 'getSubscriptionsForUser?email=' + email);
  }

  public subscribeToArticle(subscriptionData) {
    return this.httpClient.post(this.SERVER_URL + 'subscribe', subscriptionData, {headers: {"Content-Type": "application/json"}});
  }

  public unsubscribeFromArticle(unsubscriptionData) {
    return this.httpClient.post(this.SERVER_URL + 'unsubscribe', unsubscriptionData, {headers: {"Content-Type": "application/json"}});
  }

}

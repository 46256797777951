import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    static setAccessToken(accessToken) {
        sessionStorage.setItem('accessToken', accessToken);
    }

    static removeAccessToken() {
        sessionStorage.removeItem('accessToken');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = sessionStorage.token;
        const accessToken = sessionStorage.accessToken;

        if (!token) {
            return next.handle(req); 
        }

        if (req.url.includes('v1.0/users')) {
            const reqAccess = req.clone({
                headers: req.headers.set('Authorization', `Bearer ${accessToken}`),
            });
            return next.handle(reqAccess);
        }

        const req1 = req.clone({
            headers: req.headers.set('Authentication', `Bearer ${token}`),
        });

        return next.handle(req1);

    }

}

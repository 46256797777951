import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {OAuthModule} from 'angular-oauth2-oidc';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './TokenInterceptor';
import {ReactiveFormsModule} from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import { SimplebarAngularModule } from 'simplebar-angular';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LanguageSwitchComponent } from './components/language-switch/language-switch.component';




@NgModule({
  declarations: [
    AppComponent,
    LanguageSwitchComponent
  ],
  imports: [
      BrowserModule,
      HttpClientModule,
      OAuthModule.forRoot(),
      ReactiveFormsModule,
      NgSelectModule,
      FormsModule,
      BrowserAnimationsModule,
      MatButtonModule,
      MatListModule,
      MatTabsModule,
      MatIconModule,
      SimplebarAngularModule,
      MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
